import { extensionsConfig } from "client/config/extensionsConfig";
import { ReduxProvider } from "components/common/ReduxProvider/ReduxProvider";
import { ImageUploader } from "components/common/Uploader/ImageUploader/ImageUploader";
import { useResizeUploader } from "./useResizeUploader";

const ResizeUploaderContent = () => {
    const { deleteImage, deleteAllImages, filterFiles } = useResizeUploader();

    return (
        <ImageUploader
            deleteImage={deleteImage}
            deleteAllImages={deleteAllImages}
            maxUploads={1}
            analytics={{
                pageKey: "image_resizer",
                pageTitle: "Image Resizer",
            }}
            htmlFileInputId="file-input"
            skipImportAfterTaskCreate={true}
            supportedExtensions={extensionsConfig.resizer}
            filterFiles={filterFiles}
        />
    );
};

export const ResizeUploader = () => {
    return (
        <ReduxProvider>
            <ResizeUploaderContent />
        </ReduxProvider>
    );
};
